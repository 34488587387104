.ds-slideshow {
    padding-bottom: 2.5rem;

    &.menu-active {
        position: relative;
        z-index: 4;
        
        @media (min-width: $break-xl) {
            padding-left: calc(25% + 1.875rem);
        }

        @media (min-width: 1400px) {
            padding-left: calc(25% + 1rem);
        }

    }

    &-items {
        &:not(.slick-initialized) {
            display: flex;
            max-width: 100%;
            overflow-x: hidden;
            align-items: stretch;

            .ds-slideshow-item {
                flex: 0 0 78.5%;
                height: auto;

                @media (min-width: $break-sm) {
                    flex: 0 0 37.9%;
                }

                @media (min-width: $break-lg) {
                    flex: 0 0 27%;
                }

                &-vertical {
                    @media (min-width: $break-xl) {
                        flex: 0 0 241px;
                        width: 241px;
                    }

                    @media (min-width: 1400px) {
                        flex: 0 0 288px;
                        width: 288px;
                    }

                    @media (min-width: 1600px) {
                        flex: 0 0 317px;
                        width: 317px;
                    }
                }
            }
        }

        &::after {
            content: "";
            position: absolute;
            top: 1px;
            right: 0;
            bottom: 0;
            width: 168px;
            /* ширина градієнту */
            background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(249, 251, 253, 0.33));
            pointer-events: none;
            /* щоб не блокувати кліки та інші події */
        }

        &.hide-gradient::after {
            display: none;
        }

        .slick-list {
            height: 100%;
        }

        .slick-track {
            display: flex;
            align-items: center;
            overflow: visible;
            overflow-x: hidden;
            align-items: stretch;
            height: 100%;
        }

        .slick-slide {
            height: auto;
        }
    }

    &-item {
        height: 100%;
        background: #102432 linear-gradient(270deg, rgba(255, 255, 255, 0.10) 52.72%, rgba(255, 255, 255, 0.00) 84.72%);
        padding: 2rem;
        color: var(--ds-white-color);

        &:not(:last-child) {
            margin-right: 1rem;
        }

        .button.button-outline-primary {
            border-color: var(--ds-white-color);
            color: var(--ds-white-color);

            &:hover {
                background-color: var(--ds-white-color);
                color: var(--ds-secondary-dark-color);
            }
        }

        &-title,
        &-text {
            align-self: flex-start;
        }

        img {
            width: 100%;
            height: auto;
            object-fit: contain;
            max-width: 240px;
            max-height: 240px;
        }

        &-full-img {
            
            img {
                width: 100%;
                height: auto;
                object-fit: contain;
                max-width: 100%;
                max-height: 100%;
            }
        }

        &-horizontal {
            min-height: 300px;
        }
    }
}