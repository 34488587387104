.ds-footer {
    background: var(--ds-footer-bg-color);

    &-top {
        @media (min-width: $break-md) {
            border-bottom: var(--ds-main-border);
        }
    }

    &-logo {
        height: auto;
    }

    &-subscribe {
        @media (max-width: $break-sm-max) {
            border-bottom: var(--ds-main-border);
            padding-bottom: 2rem;

            > div {
                width: 100%;
                z-index: 1;
            }
        }

        #subscribeContainer {
            transition: opacity var(--ds-main-transition);

            @media (max-width: $break-sm-max) {
                width: 100%;
            }

            &.clicked {
                position: absolute;
                opacity: 0;
                transition: opacity var(--ds-main-transition);
                z-index: -1;
            }
        }

        &-text {
            @media (min-width: $break-md) {
                margin-right: 2rem;
            }

            @media (min-width: $break-lg) {
                margin-right: 3.5rem;
            }
        }

        button {
            width: 100%;

            @media (min-width: $break-sm) {
                width: auto;
            }
        }

        &-input {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            transition: opacity var(--ds-main-transition);
            width: 100%;
            z-index: -1;

            .form-control {
                @media (min-width: $break-md) {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }

            button {
                flex: 0 0 auto;
                @media (min-width: $break-md) {
                    border-top-left-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
                }

                svg {
                    width: 21px;
                    height: 18px;
                }
            }

            &.active {
                opacity: 1;
                transition: opacity var(--ds-main-transition);
                z-index: 2;

                @media (min-width: $break-lg) {
                    max-width: 510px;
                }
            }
        }
    }

    &-item {
        @media (max-width: $break-sm-max) {
            flex-grow: 1;
        }

        &-title,
        a {
            line-height: 1;
        }

        li a:hover span {
            text-decoration: underline;
            color: var(--ds-links-color);
        }

        &-title {
            white-space: nowrap;

            svg path {
                fill: var(--ds-dark-text-color);
            }
        }
        
        &-icon {
            width: 22px;
            display: grid !important;
            place-items: center;
            font-size: 1.375rem;

            &.fa-facebook-f {
                color: #3b5998;
            }

            &.fa-instagram {
                color: #e4405f;
            }

            &.fa-youtube {
                color: #c4302b;
            }

            &.fa-twitter {
                color: #55acee;
            }

            &.fa-tiktok {
                color: #000000;
            }
        }
    }

    &-contacts {

        @media (max-width: $break-sm-max) {
            padding-top: 1rem;
        }

        &-button {

            @media (max-width: $break-xs-max) {
                width: 100%;
            }
        }
    }

    &-phones-shedule {
        a:hover {
            text-decoration: none !important;
        }
    }

    &-socials {
        @media (max-width: $break-sm-max) {
            padding-top: 2rem;
            margin-top: 2rem;
            border-top: var(--ds-main-border);
        }

        ul li {
            line-height: 1;

            &:not(:last-child) {
                margin-bottom: 0.5rem;
            }
        }
    }

    &-links {
        @media (max-width: $break-sm-max) {
            padding-top: 2rem;
            margin-top: 2rem;
            border-top: var(--ds-main-border);

            & + .button-primary {
                margin-top: 2rem;
            }
        }

        li {
            line-height: 1;

            &:not(:last-child) {
                margin-bottom: 0.75rem;
            }

            a {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &-payments {
        gap: 0.25rem;

        @media (max-width: $break-sm-max) {
            border-top: var(--ds-main-border);
            margin-top: 2rem;
            padding-top: 2rem;
        }

        @media (max-width: $break-md-max) {
            flex-wrap: wrap;
        }

        &-item {
            border: var(--ds-main-border);
            display: grid;
            place-items: center;
            width: 50px;
            height: 30px;
            overflow: hidden;
            padding: 0 0.25rem;

            @media (min-width: $break-lg) {
                width: 60px;
                height: 40px;
            }

            img {
                max-width: 100%;
                transform: scale(0.9);
            }

            &-custom {
                padding: 0.125rem 0.25rem;

                img {
                    object-fit: contain;
                    max-width: 100%;
                    max-height: 100%;
                    block-size: auto;
                }
            }
        }
    }

    &-bordered {
        @media (min-width: $break-md) {
            border-top: var(--ds-main-border);
        }

        @media (min-width: $break-lg) {
            &-links {
                border-top: 0;
            }
        }
    }

    .ms-xxl-6 {
        @media (min-width: $break-xxl) {
            margin-left: 6rem !important;
        }
    }
}

#back-top {
    position: fixed;
    right: 1rem;
    bottom: 84px;
    width: 3rem;
    height: 3rem;
    z-index: 1020;
    background: rgba(var(--ds-white-color), 0.8);
    transition: background var(--ds-main-transition);
    cursor: pointer;
    border: 3px solid transparent;
    place-items: center;

    &:not([hidden]) {
        display: grid;
    }

    @media (min-width: $break-lg) {
        bottom: 120px;
        right: 2.5rem;
    }

    &:hover {
        background: rgba(var(--ds-white-color), 1);
    }

    &:focus {
        box-shadow: none;
    }

    &.active {
        display: flex;
    }

    i {
        color: var(--ds-primary-color);
    }

    svg {
        transform: rotate(-90deg); // Початок з верхньої точки
        position: absolute;
        top: -3px;
        left: -3px;

        circle {
            transition: stroke-dashoffset 0.3s ease;
        }
    }
}